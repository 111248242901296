<template>
  <div>
    <div @click="showModal = true">
      <slot>
        <b-button
          v-if="user && user.id"
          variant="success"
          size="sm"
        >
          <b-icon icon="pencil"></b-icon>
          Edit User
        </b-button>
        <b-button
          variant="success"
          v-else
          size="sm"
        >
          <b-icon icon="plus"></b-icon>
          Add User
        </b-button>
      </slot>
    </div>
    <!-- :ok-disabled="(form.role_id ? false : true)" -->
    <b-modal
      v-model="showModal"
      :title="(user && user.id) ? 'Edit User' : 'Add User'"
      @hidden="reset"
      @shown="init"
      @ok="save"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-overlay :show="loading">

        <div class="row mb-3" v-if="!user || !user.id">
          <label class="form-label text-right col-4" for="existinguser-select">
            <slot name="label">Search Existing Users</slot>
          </label>
          <vue-typeahead-bootstrap
            v-if="showUserSearch"
            :data="usersList"
            v-model="userSearch.text"
            :serializer="(s) => { return s.fullname + ' <' + s.email + '>' }"
            @hit="selectExisting($event)"
            inputName="existinguser-select"
            class="col pl-2"
            debounce="500"
          ></vue-typeahead-bootstrap>
        </div>

        <b-form-group
          label="First Name"
          label-align="right"
          label-cols="4"
        >
          <b-input v-model="form.firstname"></b-input>
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-align="right"
          label-cols="4"
        >
          <b-input v-model="form.lastname"></b-input>
        </b-form-group>

        <b-form-group
          label="Email"
          label-align="right"
          label-cols="4"
        >
          <b-input v-model="form.email"></b-input>
        </b-form-group>

        <b-form-group
          label="Phone"
          label-align="right"
          label-cols="4"
        >
          <b-input v-model="form.phonenumber"></b-input>
        </b-form-group>

        <b-form-group
          label="Organization"
          label-align="right"
          label-cols="4"
          v-if="!+organizationId && organizations && organizations.length > 1"
        >
          <b-form-select
            v-model="form.organization_id"
            :options="organizations"
          >
            <template #first>
              <b-select-option :value="null">SBS Employee</b-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Company"
          label-align="right"
          label-cols="4"
          v-if="isClient && companies && organizationCompanies.length > 1"
        >
          <b-form-select
            v-model="form.company_id"
            :options="organizationCompanies"
          >
            <template #first>
              <b-select-option :value="0">All Companies</b-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Permission Level"
          label-align="right"
          label-cols="4"
        >
          <b-form-select
            v-model="form.role_id"
            :options="(isEmployee) ? permissions.employee : permissions.client"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label="Team Name"
          label-align="right"
          label-cols="4"
          v-if="isEmployee"
        >
          <b-input v-model="form.teamname"></b-input>
        </b-form-group>

        <b-form-group
          label="Calendar Path"
          label-align="right"
          label-cols="4"
          v-if="isEmployee"
        >
          <b-input v-model="form.calendar"></b-input>
        </b-form-group>

        <div class="form-row">
          <legend class="col-4 bv-no-focus-ring col-form-label text-right" tabindex="-1">
            Role Active
            <b-icon icon="question-circle-fill" v-b-tooltip.hover title="Click Here for more info"
              @click="showinfo('Unchecking this box will make the users role inactive. They will still be able to sign in and access other companies they have active roles for.')"
            ></b-icon>
          </legend>
          <b-col>
            <b-form-checkbox
              class="pt-2"
              v-model="form.activerole"
              :value="1"
              :unchecked-value="0"
            ></b-form-checkbox>
          </b-col>
        </div>

        <div class="form-row">
          <legend class="col-4 bv-no-focus-ring col-form-label text-right" tabindex="-1">
            User Active
            <b-icon icon="question-circle-fill" v-b-tooltip.hover title="Click Here for more info"
              @click="showinfo('Unchecking this box will make the user completely inactive. They will not be able to login or access any companies or organizations.')"
            ></b-icon>
          </legend>
          <b-col>
            <b-form-checkbox
              class="pt-2"
              v-model="form.active"
              :value="1"
              :unchecked-value="0"
            ></b-form-checkbox>
          </b-col>
        </div>

        <b-form-group
          label="Force Password Change"
          label-align="right"
          label-cols="4"
        >
          <b-row no-gutters>
            <b-col>
              <b-form-checkbox
                class="pt-2"
                v-model="form.forcepwchange"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </b-col>
            <b-col>
              <b-button
                class="float-right"
                size="sm"
                @click.prevent="resetPassword"
                variant="danger"
                v-if="+form.id > 0"
              >Reset Password</b-button>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Companies Access Table for Client Users -->
        <div v-if="user && user.id && isClient" class="mt-4">
          <h5 class="mb-3">Companies Access</h5>
          <div v-if="loading" class="text-center py-3">
            <b-spinner label="Loading companies..."></b-spinner>
          </div>
          <div v-else>
            <b-table
              striped
              hover
              :items="userCompanies"
              :fields="companyFields"
              responsive="sm"
              class="mb-3"
              no-local-sorting
            >
              <template #empty>
                <div class="text-center text-muted">
                  This user does not have access to any companies.
                </div>
              </template>
              <template #cell(actions)="data">
                <div v-if="data && data.item">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="removeCompanyAccess(data.item)"
                    v-b-tooltip.hover
                    title="Remove access"
                  >
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </div>
                <div v-else>
                  <p>No action available</p>
                </div>
              </template>
            </b-table>
            <!-- <b-button
              size="sm"
              variant="outline-primary"
              @click="showCompanyModal = true"
            >
              <b-icon icon="plus"></b-icon>
              Add Company Access
            </b-button> -->
          </div>
        </div>

        <a :href="'/?mask=true&user_id=' + user.id" style="color: teal; text-decoration: underline;" v-if="canMask">Open Incognito to Masquerade</a>
      </b-overlay>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>

      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>
    </b-modal>

    <!-- Add Company Access Modal -->
    <b-modal
      v-model="showCompanyModal"
      title="Add Company Access"
      ok-title="Add Access"
      @ok="addCompanyAccess"
      ok-variant="primary"
      size="md"
    >
      <b-form-group label="Organization">
        <b-form-select
          v-model="newAccess.organization_id"
          :options="organizations"
          @change="loadOrganizationCompanies"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Company">
        <b-form-select
          v-model="newAccess.company_id"
          :options="availableCompanies"
          :disabled="!newAccess.organization_id || !availableCompanies.length"
        >
          <template #first>
            <b-select-option :value="0">All Companies</b-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Role">
        <b-form-select
          v-model="newAccess.role_id"
          :options="permissions.client"
        ></b-form-select>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import AdminService from '@/services/AdminService'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import UserService from '@/services/UserService'

export default {
  name: 'UserEdit',
  components: {
    VueTypeaheadBootstrap
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    organizationId: {
      type: Number,
      default: null
    },
    companyId: {
      type: Number,
      default: null
    },
    organizations: {
      type: Array,
      default: null
    },
    companies: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      showModal: false,
      showCompanyModal: false,
      form: {
        id: 0,
        organization_id: null,
        company_id: null,
        firstname: null,
        lastname: null,
        phonenumber: null,
        email: null,
        userrole_id: null,
        role_id: null,
        teamname: null,
        calendar: null,
        forcepwchange: 1,
        active: 1,
        activerole: 1
      },
      canMask: false,
      formOriginal: null,
      permissions: {
        employee: [
          { value: 10, text: 'Administrator' },
          { value: 20, text: 'Bookkeeper' },
          { value: 40, text: 'Data Entry' }
        ],
        client: [
          { value: 10, text: 'Administrator' },
          { value: 30, text: 'Client' }
        ]
      },
      usersList: [],
      userSearch: {
        text: '',
        groupby: 'u.id'
      },
      showUserSearch: true,

      // Companies access data
      userCompanies: [],
      companyFields: [
        { key: 'organization', label: 'Organization' },
        { key: 'company', label: 'Company' }
      ],
      newAccess: {
        organization_id: null,
        company_id: null,
        role_id: 30, // Default to Client role
        user_id: 0
      },
      availableCompanies: []
    }
  },

  methods: {
    init () {
      this.formOriginal = { ...this.form }
      if (this.user) {
        this.form.id = this.user.id
        this.form.organization_id = this.user.organization_id
        this.form.company_id = this.user.company_id
        this.form.firstname = this.user.firstname
        this.form.lastname = this.user.lastname
        this.form.email = this.user.email
        this.form.phonenumber = this.user.phonenumber
        this.form.teamname = this.user.teamname
        this.form.calendar = this.user.calendar
        this.form.role_id = this.user.role_id
        this.form.active = this.user.active
        this.form.activerole = this.user.activerole
        this.form.forcepwchange = this.user.forcepwchange
        this.form.userrole_id = this.user.userrole_id

        // If editing an existing user who is a client, load their companies
        if (this.user.id && this.isClient) {
          this.loadUserCompanies()
        }
      } else {
        if (+this.organizationId > 0) {
          this.form.organization_id = +this.organizationId
        }
        if (+this.companyId > 0) {
          this.form.company_id = +this.companyId
        }
      }

      UserService.getUserInfo().then(
        (user) => {
          this.canMask = (+user.id === 1) && this.user
        }
      )
    },

    reset () {
      this.form = { ...this.formOriginal }
    },

    save () {
      if (this.loading) {
        return false
      }
      this.loading = true
      AdminService.saveUser(this.form).then((response) => {
        // this.$aimNotify.notify(response)
        this.$emit('refresh')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    resetPassword () {
      this.$bvModal.msgBoxConfirm('You are about to reset the password for user "' + this.form.firstname + ' ' + this.form.lastname + '". A new random password will be generated and emailed to the user.')
        .then(value => {
          if (value) {
            this.loading = true
            AdminService.resetPassword(this.form.id).then(
              (response) => {
                this.$aimNotify.notify(response)
              }
            ).catch((err) => {
              this.$aimNotify.error(err.response)
            }).finally(() => {
              this.loading = false
            })
          }
        })
    },

    selectExisting (u) {
      this.form.firstname = u.firstname
      this.form.lastname = u.lastname
      this.form.email = u.email
      this.form.phonenumber = u.phonenumber
      this.form.teamname = u.teamname
      this.form.calendar = u.calendar
      this.form.activerole = u.activerole
      this.form.active = u.active
      this.form.forcepwchange = u.forcepwchange

      this.userSearch.text = ''
      this.showUserSearch = false
      this.$nextTick(() => {
        this.showUserSearch = true
      })
    },

    showinfo (txt) {
      this.$bvModal.msgBoxOk(txt)
    },

    // Companies access methods
    loadUserCompanies () {
      if (!this.user || !this.user.id) return

      this.loading = true
      AdminService.getOrganizationCompanies(this.user.id).then(
        (res) => {
          // Map the data from the API and filter out entries where company is N/A
          this.userCompanies = res.data.info.companies
            .filter(c => c.company_name && c.company_name !== 'N/A')
            .map(c => {
              return {
                id: c.id,
                organization: c.organization_name || 'N/A',
                organization_id: c.organization_id,
                company: c.company_name,
                company_id: c.company_id,
                actions: ''
              }
            })
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    loadOrganizationCompanies () {
      if (!this.newAccess.organization_id) {
        this.availableCompanies = []
        return
      }

      this.availableCompanies = this.companies
        .filter(c => +c.organization_id === +this.newAccess.organization_id)
        .map(c => ({ value: c.value, text: c.text }))
    },

    addCompanyAccess () {
      if (!this.user || !this.user.id) return

      const accessData = {
        user_id: this.user.id,
        organization_id: this.newAccess.organization_id,
        company_id: this.newAccess.company_id || 0,
        role_id: this.newAccess.role_id,
        active: 1
      }

      this.loading = true
      AdminService.addUserCompanyAccess(accessData).then(
        (res) => {
          this.$aimNotify.notify(res.data)
          this.loadUserCompanies()
          this.newAccess = {
            organization_id: null,
            company_id: null,
            role_id: 30
          }
          this.showCompanyModal = false
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    removeCompanyAccess (item) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to remove this company access?')
        .then(value => {
          if (value) {
            this.loading = true
            AdminService.removeUserCompanyAccess(item.id).then(
              (res) => {
                this.$aimNotify.notify(res.data)
                this.loadUserCompanies()
              }
            ).catch((err) => {
              this.$aimNotify.error(err.response)
            }).finally(() => {
              this.loading = false
            })
          }
        })
    }
  },

  computed: {
    isEmployee () {
      return (this.form && (this.form.organization_id === null && this.form.company_id === null))
    },

    isClient () {
      return (!this.form || (!this.form.id && this.form.organization_id) || this.form.company_id !== null || this.form.role_id === 30)
    },

    organizationCompanies () {
      const cmps = []
      if (this.form && +this.form.organization_id > 0 && this.companies && this.companies.length > 0) {
        this.companies.forEach((c) => {
          if (+c.organization_id === +this.form.organization_id) {
            cmps.push(c)
          }
        })
      }
      return cmps
    }
  },

  watch: {
    userSearch: {
      handler (n) {
        if (n.text === '' || !n.text) {
          this.usersList = []
          return
        }
        AdminService.getUsers(n).then(
          (res) => {
            this.usersList = res.data.info.users
          }
        )
      },
      deep: true
    },

    user (newVal) {
      if (newVal && newVal.id && this.isClient) {
        this.loadUserCompanies()
      }
    }
  }
}
</script>
