<template>
  <b-overlay :show="loading">
    <b-card class="mb-4 card-border text--black q-table-container">
      <template #header>
        <slot></slot>
      </template>

      <b-form
        inline
        class="mb-3"
        @submit.prevent="search(1)"
        @reset.prevent="reset"
      >
        <b-form-group
          label="Search:"
          label-cols="auto"
          label-size="sm"
          class="pr-2"
        >
          <b-form-input
            v-model="filters.text"
            debounce="500"
            class="d-inline w-auto"
            size="sm"
            @keyup.native.enter="search"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Organization:"
          label-cols="auto"
          label-size="sm"
          class="pr-2"
          v-if="!organizationId"
        >
          <b-form-select
            v-model="filters.organization_id"
            :options="formdata.organizations"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="0">Select Organization</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Company:"
          label-cols="auto"
          label-size="sm"
          class="pr-2"
          v-if="!companyId && filteredCompanies.length > 1"
        >
          <b-form-select
            v-model="filters.company_id"
            :options="filteredCompanies"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="0">Select Company</b-form-select-option>
              <b-form-select-option :value="'SBS'">Streamlined Business Solutions</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-button
          type="submit"
          variant="success"
          size="sm"
          v-if="+totalUsers > +filters.max || !organizationId"
        >Search</b-button>
        <b-button
          type="reset"
          variant="danger"
          size="sm"
          v-if="+totalUsers > +filters.max || !organizationId"
          class="ml-1"
        >Reset</b-button>

        <UserEdit
          class="ml-auto"
          @refresh="refresh"
          :organizations="formdata.organizations"
          :companies="formdata.companies"
          :organization-id="organizationId"
          :company-id="companyId"
        >
          <template>
            <b-button
              type="button"
              variant="info"
              size="sm"
            >Add User</b-button>
          </template>
        </UserEdit>

      </b-form>

      <b-table
        id="usersTable"
        @sort-changed="sortingChanged"
        :items="users"
        no-local-sorting
        :fields="fields"
        :sort-by.sync="filters.order.column"
        :sort-desc.sync="filters.order.desc"
        tbody-td-class="vertical-align-middle"
        striped
        small
        style="font-size: 90%;"
        bordered
        sticky-header="600px"
        :show-empty="searched"
      >
        <template #cell(lastname)="data">
          {{ data.item.lastname }}, {{ data.item.firstname }}
        </template>
        <template #cell(lastlogin)="data">
          {{ data.item.lastlogin ? data.item.lastlogin : 'Never' }}
        </template>

        <template #cell(company_id)="data">
          {{ getCompanyName(data.item.company_id, data.item.organization_id) }}
        </template>

        <template #cell(actions)="data">
          <template>
            <UserEdit
              @refresh="refresh"
              :user="data.item"
              :companies="formdata.companies"
            >
                <b-button
                  variant="info"
                  size="sm"
                >
                  <b-icon icon="pencil-square"></b-icon>
                </b-button>
            </UserEdit>
            <b-button v-if="!disabled" variant="danger" size="sm" @click="removeUser(data.item)" v-b-tooltip.hover title="Remove user from company">
              <b-icon icon="x-square"></b-icon>
            </b-button>
          </template>
        </template>
        <template #empty>
          We did not find any results with the specified filters
        </template>
      </b-table>

      <b-pagination
        class="float-right"
        v-if="totalUsers > filters.max"
        v-model="filters.page"
        :total-rows="totalUsers"
        :per-page="filters.max"
        aria-controls="usersTable"
        @change="changePage"
      ></b-pagination>

      <div
        class="pl-1 text-left"
        v-if="totalUsers > 10"
      >
        Per page:
        <span
          @click="filters.max = 10"
          :class="filters.max == 10 ? 'active' : ''"
          class="ml-2 link"
        >10</span>
        <span
          href="javascript:void(0)"
          @click="filters.max = 25"
          :class="filters.max == 25 ? 'active' : ''"
          class="ml-2 link"
        >25</span>
        <span
          href="javascript:void(0)"
          @click="filters.max = 50"
          :class="filters.max == 50 ? 'active' : ''"
          class="ml-2 link"
        >50</span>
        <span
          href="javascript:void(0)"
          @click="filters.max = 100"
          :class="filters.max == 100 ? 'active' : ''"
          class="ml-2 link"
        >100</span>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
import AdminService from '@/services/AdminService'
import StorageService from '@/services/StorageService'
import UserEdit from '@/components/admin/user/Edit'

export default {
  name: 'UserList',
  components: { UserEdit },
  props: {
    organizationId: {
      type: Number,
      default: 0
    },
    companyId: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      loading: false,
      searched: false,
      filters: {
        page: 1,
        max: 10,
        from: 'companies',
        order: [{
          column: 'lastlogin',
          direction: 'desc'
        }],
        text: '',
        organization_id: 0,
        company_id: 0,
        groupby: 'u.id',
        company_orgid: 0
      },
      company_sbs: 0,
      totalUsers: 0,
      users: [],
      fields: [
        { key: 'lastname', label: 'Name', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left' },
        { key: 'email', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left' },
        { key: 'company', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left' },
        { key: 'role', sortable: true, tdClass: 'align-middle' },
        { key: 'lastlogin', label: 'Last Login', sortable: true, sortDirection: 'desc', tdClass: 'align-middle' },
        { key: 'actions', label: '', sortable: false, tdClass: 'text-center align-middle' }
      ],
      formdata: {
        organizations: [],
        companies: []
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      AdminService.init().then((response) => {
        const orgs = []
        const cmps = []
        response.data.info.organizations.forEach((o) => {
          orgs.push({ text: o.name, value: +o.id })
          o.companies.forEach((c) => {
            cmps.push({ text: c.name, value: +c.id, organization_id: +o.id })
          })
        })

        this.formdata.organizations = orgs.sort((a, b) => ('' + a.text).localeCompare(b.text))
        this.formdata.companies = cmps.sort((a, b) => ('' + a.text).localeCompare(b.text))
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        if (+this.organizationId > 0) {
          this.filters.organization_id = +this.organizationId
          // set the proper labels when there is an organization id
          let k = null
          this.fields.some((e, i) => {
            if (e.key === 'company') {
              k = i
              return true
            } else {
              return false
            }
          })

          if (k) {
            this.fields[k].label = 'Organization'
            this.fields[k].sortable = false
          }
        }
        if (+this.companyId > 0) {
          this.filters.company_id = +this.companyId
        }
        this.loading = false
        if (this.filters.organization_id > 0 || this.filters.company_id > 0) {
          this.search(1)
        }
      })
    },

    removeUser (data) {
      if (confirm('Are you sure you want to remove this user?')) {
        AdminService.removeUser(data).then(
          this.$router.go()
        )
      }
    },

    refresh () {
      this.$router.go()
    },

    search (page = null) {
      if (this.loading) {
        return false
      }
      this.loading = true
      if (page !== null) {
        this.filters.page = +page
      }
      if (this.filters.organization_id === 0 && this.filters.company_id > 0) {
        const selectedCompany = this.formdata.companies.find(company => +company.value === +this.filters.company_id)
        if (selectedCompany) {
          this.filters.company_orgid = selectedCompany.organization_id
        }
      }
      if (this.filters.company_id === 'SBS' && this.filters.organization_id === 0) {
        console.log('inside sbs')
        this.company_sbs = 'SBS'
        this.filters.company_orgid = null
        this.filters.company_id = null
        this.filters.organization_id = null
        this.filters.activerole = 1
      }
      AdminService.getUsers(this.filters).then((response) => {
        this.users = response.data.info.users
        this.totalUsers = response.data.info.totalResults
        this.searched = true
        if (this.company_sbs === 'SBS') {
          this.filters.company_id = 'SBS'
          this.filters.organization_id = 0
          this.company_sbs = 0
        }
      }).catch((error) => {
        console.dir(error)
        this.$aimNotify.error(error.response)
      }).finally(() => {
        this.$updateloading(-1)
        this.loading = false
        this.user = StorageService.get('user')
      })
    },

    reset () {
      this.filters.text = null
      this.filters.organization_id = 0
      this.filters.company_id = 0
      this.totalUsers = 0
      this.users = []
      this.searched = false
    },

    changePage (page) {
      this.filters.page = page
    },

    sortingChanged (event) {
      this.filters.page = 1
      this.filters.order = []

      const direction = event.sortDesc ? 'desc' : 'asc'

      if (event.sortBy === 'lastname') {
        this.filters.order.push({
          column: 'lastname',
          direction: direction
        })

        this.filters.order.push({
          column: 'firstname',
          direction: direction
        })
      } else {
        this.filters.order.push({
          column: event.sortBy,
          direction: direction
        })
      }
      this.search()
    },

    getOrganizationName (id) {
      let name = ''
      if (+id > 0) {
        this.formdata.organizations.some((o) => {
          if (+o.value === +id) {
            name = o.text
            return true
          } else return false
        })
      }
      return name
    },

    getCompanyName (id, organizationId) {
      let name = ''
      if (+id > 0) {
        this.formdata.companies.some((c) => {
          if (+c.value === +id) {
            name = c.text
            return true
          } else return false
        })
      } else if (+organizationId > 0) {
        name = this.getOrganizationName(organizationId)
      }
      return name
    }
  },

  computed: {
    filteredCompanies () {
      if (+this.filters.organization_id > 0) {
        const cmps = []
        this.formdata.companies.forEach((c) => {
          if (+c.organization_id === +this.filters.organization_id) {
            cmps.push(c)
          }
        })
        return cmps
      } else {
        return this.formdata.companies
      }
    }
  },

  watch: {
    'filters.page': function (n) {
      if (n) {
        this.$nextTick(() => {
          this.search()
        })
      }
    },
    'filters.max': function (n) {
      if (n) {
        this.$nextTick(() => {
          this.search()
        })
      }
    }
  }
}
</script>
