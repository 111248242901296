var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _c(
        "b-card",
        {
          staticClass: "mb-4 card-border text--black q-table-container",
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [_vm._t("default")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "b-form",
            {
              staticClass: "mb-3",
              attrs: { inline: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search(1)
                },
                reset: function($event) {
                  $event.preventDefault()
                  return _vm.reset.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "pr-2",
                  attrs: {
                    label: "Search:",
                    "label-cols": "auto",
                    "label-size": "sm"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "d-inline w-auto",
                    attrs: { debounce: "500", size: "sm" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.filters.text,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "text", $$v)
                      },
                      expression: "filters.text"
                    }
                  })
                ],
                1
              ),
              !_vm.organizationId
                ? _c(
                    "b-form-group",
                    {
                      staticClass: "pr-2",
                      attrs: {
                        label: "Organization:",
                        "label-cols": "auto",
                        "label-size": "sm"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.formdata.organizations,
                          size: "sm"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: 0 } },
                                    [_vm._v("Select Organization")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1478558982
                        ),
                        model: {
                          value: _vm.filters.organization_id,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "organization_id", $$v)
                          },
                          expression: "filters.organization_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.companyId && _vm.filteredCompanies.length > 1
                ? _c(
                    "b-form-group",
                    {
                      staticClass: "pr-2",
                      attrs: {
                        label: "Company:",
                        "label-cols": "auto",
                        "label-size": "sm"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.filteredCompanies, size: "sm" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: 0 } },
                                    [_vm._v("Select Company")]
                                  ),
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: "SBS" } },
                                    [_vm._v("Streamlined Business Solutions")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2825893188
                        ),
                        model: {
                          value: _vm.filters.company_id,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "company_id", $$v)
                          },
                          expression: "filters.company_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  staticClass: "pr-2",
                  attrs: {
                    label: "Role:",
                    "label-cols": "auto",
                    "label-size": "sm"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.roleOptions, size: "sm" },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "" } },
                              [_vm._v("All Roles")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.role_id,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "role_id", $$v)
                      },
                      expression: "filters.role_id"
                    }
                  })
                ],
                1
              ),
              +_vm.totalUsers > +_vm.filters.max || !_vm.organizationId
                ? _c(
                    "b-button",
                    {
                      attrs: { type: "submit", variant: "success", size: "sm" }
                    },
                    [_vm._v("Search")]
                  )
                : _vm._e(),
              +_vm.totalUsers > +_vm.filters.max || !_vm.organizationId
                ? _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { type: "reset", variant: "danger", size: "sm" }
                    },
                    [_vm._v("Reset")]
                  )
                : _vm._e(),
              _c(
                "UserEdit",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    organizations: _vm.formdata.organizations,
                    companies: _vm.formdata.companies,
                    "organization-id": _vm.organizationId,
                    "company-id": _vm.companyId
                  },
                  on: { refresh: _vm.refresh }
                },
                [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { type: "button", variant: "info", size: "sm" }
                      },
                      [_vm._v("Add User")]
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _c("b-table", {
            staticStyle: { "font-size": "90%" },
            attrs: {
              id: "usersTable",
              items: _vm.users,
              "no-local-sorting": "",
              fields: _vm.fields,
              "sort-by": _vm.filters.order.column,
              "sort-desc": _vm.filters.order.desc,
              "tbody-td-class": "vertical-align-middle",
              striped: "",
              small: "",
              bordered: "",
              "sticky-header": "600px",
              "show-empty": _vm.searched
            },
            on: {
              "sort-changed": _vm.sortingChanged,
              "update:sortBy": function($event) {
                return _vm.$set(_vm.filters.order, "column", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.filters.order, "column", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.filters.order, "desc", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.filters.order, "desc", $event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "cell(lastname)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.lastname) +
                        ", " +
                        _vm._s(data.item.firstname) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(lastlogin)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          data.item.lastlogin ? data.item.lastlogin : "Never"
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(company_id)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getCompanyName(
                            data.item.company_id,
                            data.item.organization_id
                          )
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(data) {
                  return [
                    [
                      _c(
                        "UserEdit",
                        {
                          attrs: {
                            user: data.item,
                            companies: _vm.formdata.companies
                          },
                          on: { refresh: _vm.refresh }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { variant: "info", size: "sm" } },
                            [
                              _c("b-icon", { attrs: { icon: "pencil-square" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.disabled
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                variant: "danger",
                                size: "sm",
                                title: "Remove user from company"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeUser(data.item)
                                }
                              }
                            },
                            [_c("b-icon", { attrs: { icon: "x-square" } })],
                            1
                          )
                        : _vm._e()
                    ]
                  ]
                }
              },
              {
                key: "empty",
                fn: function() {
                  return [
                    _vm._v(
                      " We did not find any results with the specified filters "
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm.totalUsers > _vm.filters.max
            ? _c("b-pagination", {
                staticClass: "float-right",
                attrs: {
                  "total-rows": _vm.totalUsers,
                  "per-page": _vm.filters.max,
                  "aria-controls": "usersTable"
                },
                on: { change: _vm.changePage },
                model: {
                  value: _vm.filters.page,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "page", $$v)
                  },
                  expression: "filters.page"
                }
              })
            : _vm._e(),
          _vm.totalUsers > 10
            ? _c("div", { staticClass: "pl-1 text-left" }, [
                _vm._v(" Per page: "),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 10 ? "active" : "",
                    on: {
                      click: function($event) {
                        _vm.filters.max = 10
                      }
                    }
                  },
                  [_vm._v("10")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 25 ? "active" : "",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.filters.max = 25
                      }
                    }
                  },
                  [_vm._v("25")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 50 ? "active" : "",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.filters.max = 50
                      }
                    }
                  },
                  [_vm._v("50")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 100 ? "active" : "",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.filters.max = 100
                      }
                    }
                  },
                  [_vm._v("100")]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }